<template>
  <div class="app-container">
    <magic-editor :config="config" />
  </div>
</template>
<script>
// 引入组件
import MagicEditor from "magic-editor";
// 引入样式
import "magic-editor/dist/magic-editor.css";
import { getToken } from "@/utils/auth";

export default {
  name: "App",
  components: {
    MagicEditor,
  },
  data() {
    return {
      config: {
        baseURL: process.env.VUE_APP_BASE_API + "magic/web", //配置后台服务
        serverURL: process.env.VUE_APP_BASE_API, //配置接口实际路径
        request: {
          beforeSend: (config) => {
            config.headers["token"] = getToken();
            return config;
          },
        },
        // 其它配置请参考文档
      },
    };
  },
};
</script>

<style>
  body, html{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  #app, .app-container {
    height: 100%;
  }
</style>